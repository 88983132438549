//Overwrite the target with the source, if the source exists
const deepMergeObjects = (target, source) => {
	if (typeof target !== "object" || typeof source !== "object") {
		throw new Error("Both arguments must be objects")
	}

	// If source is an array, replace target with it. Otherwise, use target array
	if (Array.isArray(source)) {
		return [...source]
	} else if (Array.isArray(target)) {
		return [...target]
	}

	for (const key in source) {
		if (source.hasOwnProperty(key)) {
			if (source[key] instanceof Object) {
				if (!target.hasOwnProperty(key) || typeof target[key] !== "object") {
					target[key] = Array.isArray(source[key]) ? [] : {}
				}
				target[key] = deepMergeObjects(target[key], source[key])
			} else {
				target[key] = source[key]
			}
		}
	}
	return target
}

export default deepMergeObjects
